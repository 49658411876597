import React, { useState } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { navigate } from 'gatsby-link';
import Content, { HTMLContent } from '../components/Content';
import RouterTracker from '../components/RouterTracker';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import Button from '../components/Button';
import ThemeContext from '../context/ThemeContext';

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

const ContactPageTemplateStyles = styled.article(
  (props) => `
  padding: ${props.theme.size.base * 13}px 
    ${props.theme.size.base * 4}px 
    ${props.theme.size.base * 4}px;
  ${
    (props.breakpoints.lg || props.breakpoints.xl) &&
    `
   padding: ${props.theme.size.base * 8}px;
  `
  };
  
  .body {
    position: relative;
    z-index: 500;
    max-width: ${props.theme.size.base * 128}px;
    margin: 0 auto;
  }
  .contact {
    margin: ${props.theme.size.base * -2}px;
    display: flex;
    flex-direction: ${
      props.breakpoints.lg || props.breakpoints.xl ? 'row' : 'column'
    };
  }
  .contact-info {
    flex: ${
      props.breakpoints.lg || props.breakpoints.xl ? '0 0 50%' : '1 1 100%'
    };
  }
  .contact-form {
    flex: ${
      props.breakpoints.lg || props.breakpoints.xl ? '0 0 50%' : '1 1 100%'
    };
  }
  .content {
    padding: ${props.theme.size.base * 2}px;
  }
`
);

export const ContactPageTemplate = ({
  title,
  content,
  contentComponent,
  breakpoints,
}) => {
  const PageContent = contentComponent || Content;
  const [state, setState] = useState({ isValidated: false });

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        'form-email': form.getAttribute('email'),
        'form-message': form.getAttribute('message'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error));
  };

  return (
    <ThemeContext.Consumer>
      {(theme) => (
        <ContactPageTemplateStyles theme={theme} breakpoints={breakpoints}>
          <RouterTracker />
          <div className='body'>
            <section className='typesetter'>
              <h1>{title}</h1>
              <div className='contact'>
                <div className='contact-info'>
                  <div className='content'>
                    <PageContent content={content} />
                  </div>
                </div>
                <div className='contact-form'>
                  <div className='content'>
                    <h3>Drop us a line</h3>
                    <form
                      name='contact'
                      method='post'
                      action='/contact/thank-you/'
                      data-netlify='true'
                      data-netlify-honeypot='bot-field'
                      onSubmit={handleSubmit}
                    >
                      {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                      <input type='hidden' name='form-name' value='contact' />
                      <div hidden>
                        <label>
                          Don’t fill this out:{' '}
                          <input name='bot-field' onChange={handleChange} />
                        </label>
                      </div>
                      <div className='field'>
                        <label className='label' htmlFor={'name'}>
                          Your name
                        </label>
                        <div className='control'>
                          <input
                            className='input'
                            type={'text'}
                            name={'name'}
                            onChange={handleChange}
                            id={'name'}
                            required={true}
                          />
                        </div>
                      </div>
                      <div className='field'>
                        <label className='label' htmlFor={'email'}>
                          Email
                        </label>
                        <div className='control'>
                          <input
                            className='input'
                            type={'email'}
                            name={'email'}
                            onChange={handleChange}
                            id={'email'}
                            required={true}
                          />
                        </div>
                      </div>
                      <div className='field'>
                        <label className='label' htmlFor={'message'}>
                          Message
                        </label>
                        <div className='control'>
                          <textarea
                            className='textarea'
                            name={'message'}
                            onChange={handleChange}
                            id={'message'}
                            required={true}
                          />
                        </div>
                      </div>
                      <div className='field'>
                        <Button shape={4} color='tertiary' type='submit'>
                          Send →
                        </Button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </ContactPageTemplateStyles>
      )}
    </ThemeContext.Consumer>
  );
};

ContactPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const ContactPage = ({ data }) => {
  const { markdownRemark: post } = data;
  const breakpoints = useBreakpoint();

  return (
    <ContactPageTemplate
      contentComponent={HTMLContent}
      title={post.frontmatter.title}
      content={post.html}
      breakpoints={breakpoints}
    />
  );
};

ContactPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ContactPage;

export const contactPageQuery = graphql`
  query ContactPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
